/* ..............v............. */
.servicemod button {
        display: block;
        margin: 13px auto;
        min-width: 153px;
      }
      .servicemod button {
        display: block;
        margin: 20px auto;
        min-width: 153px;
        background-color: #9c27b0;
        color: white;
        font-size: 14px;
        border: 1px solid #9c27b0;
        border-radius: 3px;
        padding: 8px;
        box-shadow: -7px -3px 7px 3px #c4c2c2;
        font-weight: 700;
    }
    .servicemod p{
        font-size: 29px;
        font-weight: bold;
        margin-bottom: 37px;
        margin-top: 18px;
    }
    .sertext {
      font-size: 16px;
      margin-bottom: 37px;
      margin-top: 18px;
    }